<!-- =========================================================================================
    File Name: ItemListView.vue
    Description: Item Component - List VIew
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="p-6 bg-white shadow-md rounded-lg flex flex-col lg:flex-row lg:justify-center lg:items-center lg:space-x-6"
    v-on="$listeners"
  >

    <!-- delete -->
    <div class="text-right lg:text-left"><slot name="remove"/></div>
    <!-- image -->
      <!-- img :src="item.images[0].image" :alt="item.name" class="p-4" -->
      <!-- src="https://domf5oio6qrcr.cloudfront.net/medialibrary/7798/c5a743ce-27ab-4b0e-b3cf-167a7d29e45316207267658642.jpg" -->
      <img
        @click="navigate_to_detail_view"
        class="block w-6/12 lg:w-2/12 mx-auto object-contain mb-4 lg:mb-0"
        :src="itemImgSrc(item)"
      />

    <!-- product -->
    <div class="flex flex-col justify-center w-full">
      <div class="text-lg opacity-75 hidden">{{ item.productType }}</div>
      <div
        class="font-semibold cursor-pointer title-min-height"
        style="text-wrap: balance;"
        @click="navigate_to_detail_view"
      >
        {{ item.name }}
      </div>

      <div
        v-if="isDiscounted()"
        class="mt-2 font-semibold text-primary my-3"
      >
        <span v-if="!isLoggedInSuperAdmin">
          <span class="line-through">${{ getActualPrice() | formatDecimals }}</span> ${{ getDiscountedPrice() | formatDecimals }}
        </span>
        <span
          v-else
          class="flex flex-col"
        >
          <span class="flex items-center mb-2">
            <span class="mr-1">$</span>
            <vs-input
              type="number"
              :is-disabled="disableInput"
              :disabled="disableInput"
              v-model.number="item.superAdminDiscountPrice"
              @change="changePrice('superAdminDiscountPrice')()"
              @focusout = "item.superAdminDiscountPrice = item.superAdminDiscountPrice || 0"
            />
          </span>
          <span class="line-through mb-2">
            Original Price: ${{ getActualPrice(true) | formatDecimals }}
          </span>
          <span>
            Discounted Price: ${{ getDiscountedPrice() | formatDecimals }}
          </span>
        </span>
      </div>
      <div
        v-else
        class="mb-4 mt-1 font-semibold text-lg text-primary flex items-center"
      >
        <span class="mr-1">$</span>
        <span v-if="!isLoggedInSuperAdmin">{{ item.price | formatDecimals }}</span>
        <vs-input
          v-else
          type="number"
          :is-disabled="disableInput"
          :disabled="disableInput"
          v-model.number="item.superAdminPrice"
          @change="changePrice('superAdminPrice')()"
          @focusout = "item.superAdminPrice = item.superAdminPrice || 0"
        />
      </div>
      <patient-details :patient="item.patient" @handleUpdatePatient="handleUpdatePatient" v-if="item.patient" :isEditToggled="isEditToggled" @changeEditToggle="isEditToggled = $event" />
      <bundle-details :bundle="item.bundleItems" v-if="item.bundleItems" />
      <bundled-items-details :items="item.bundledItems" v-if="item.bundledItems && item.bundledItems.length"/>
    </div>

    <!-- action -->
    <div class="flex items-center justify-between lg:space-x-6 w-full lg:w-auto"
      :style="isLoggedInSuperAdmin && 'margin-top: -40px'"
      v-if="!isEditToggled"
    >
      <div class="w-full">
        <p class="text-sm lg:text-xs"> Sub Total:</p>
        <p class="font-semibold text-lg">${{ getSubTotal() | formatDecimals }}</p>
      </div>
      <slot name="action-buttons" />
    </div>
  </div>
</template>

<script>
import PatientDetails from "./PatientDetails";
import BundleDetails from "./BundleDetails.vue";
import BundledItemsDetails from "./BundledItemsDetails";
import { debounce } from "lodash";
import { mapActions } from "vuex";

export default {
  inject: ["getRoleRouteName"],
  components: {
    PatientDetails,
    BundleDetails,
    BundledItemsDetails,
  },
  data() {
    return {
      quantity: 0,
      userTypeAdmin: undefined,
      disableInput: false,
      isEditToggled: false,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    discounts: {
      type: Array,
      required: true
    },
    hasClinicDiscount: {
      type: Function,
      required: true
    }
  },
  computed: {
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    isLoggedInSuperAdmin() {
      return this.$store.state.AppActiveUser.userType === "superAdmin"
    }
  },
  methods: {
    ...mapActions("clinic", ["updateScriptProductPatientDetails"]),
    async handleUpdatePatient(patient){
      try {
        const payload = {
          id: this.item._id,
          payload: patient
        }
        const res = await this.updateScriptProductPatientDetails(payload);
        if (res.status == 200) {
          this.$vs.notify({
            title: "Success",
            text: "Patient details updated successfully.",
            color: "success",
          });
          this.isEditToggled = false;
          this.$emit("fetchCartItems");
        }
      } catch (error) {
        console.error("ERR: ", error);
      }
    },
    changePrice(key) {
      return () => {
        // disable input first
        this.disableInput = true

        return debounce(() => {
          const item = this.item;
          if (key === 'superAdminPrice') item.adjustedPrice = item[key] * 100
          if (key === 'superAdminDiscountPrice' && this.isDiscounted()) item.adjustedDiscountPrice = item[key] * 100

          // remove keys superAdminDiscountPrice if not discounted
          if (!this.isDiscounted()) {
            delete item.superAdminDiscountPrice
            delete item.adjustedDiscountPrice
            delete item.superAdminDiscountPriceFrom
          }

          // update cart item
          this.$store.dispatch("eCommerce/updateItemQuantity", {
            item,
          });

          setTimeout(() => {
            // fetch cart items
            this.$emit('fetchCartItems')

            // enable input
            this.disableInput = false
          }, 500)
        }, 500)()
      }
    },
    isDiscounted() {
      return this.discounts.some(discount=>discount.productIds.some(id=>id===this.item.productStoreId));
    },
    getActualPrice(isOriginalPrice = false) {
      // use price as default
      let price = this.item.price

      // has discount use listPrice as price
      const rule = this.discounts.filter(discount=>discount.productIds.some(id=>id===this.item.productStoreId))[0].rule;
      if (rule.discount_price && rule.discount_price === 'List Price') price = this.item.listPrice;

      // if superAdminDiscountPrice exists, use superAdminDiscountPrice
      if (this.item.hasOwnProperty('superAdminDiscountPrice') && !isOriginalPrice) price = this.item.superAdminDiscountPrice * 100

      return price
    },
    getDiscountedPrice() {
      let price = this.item.price
      const rule = this.discounts.filter(discount=>discount.productIds.some(id=>id===this.item.productStoreId))[0].rule;

      // has bulk discount rule & clinic discount, bulk discount < clinic discount
      if (rule && this.hasClinicDiscount(this.item.productStoreId) && this.item.originalPrice) price = this.item.originalPrice

      // discount is List Price, use listPrice
      if (rule.discount_price && rule.discount_price === 'List Price') price = this.item.listPrice

      // if key superAdminDiscountPrice exists, override discount price
      if (this.item.hasOwnProperty('superAdminDiscountPrice')) price = this.item.superAdminDiscountPrice * 100

      if (rule.discount_price && rule.discount_price === 'List Price' && rule.discount_type === 'percentage') {
        return price - (price * (rule.discount_value/100));
      } else if(rule.discount_price && rule.discount_price === 'List Price' && rule.discount_type === 'fix') {
        return price - (rule.itemDiscount * 100)
      } else if(rule.discount_type === 'percentage') {
        return price - (price * (rule.discount_value/100));
      }

      return price - (rule.itemDiscount * 100);
    },
    getSubTotal() {
      let price = this.item.price

      if (this.item.hasOwnProperty('superAdminPrice') && this.isLoggedInSuperAdmin) price = this.item.superAdminPrice * 100

      if (this.isDiscounted()) {
        return this.getDiscountedPrice() * this.item.quantity;
      }
      return price * this.item.quantity;
    },
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    selectedChangeQuantity(quantity) {
      this.item.selectedQuantity = quantity;
    },
    additemInCart(item) {
      this.$store.dispatch("eCommerce/additemInCart", item);
    },
    cartButtonClicked(item) {
      this.isInCart(item.objectID)
        ? this.$router.push("/apps/eCommerce/checkout").catch(() => {})
        : this.additemInCart(item);
    },
    navigate_to_detail_view() {

      let routername;

      if(this.userTypeAdmin && this.userTypeAdmin === "superAdmin") {
        routername = "SuperAdminProductDetail"

      } else {
        routername = this.getRoleRouteName("ProductDetail")
      }

      this.$router
        .push({
          name: routername,
          params: {
            id: this.item.productStoreId,
          },
        })
        .catch(() => {});

    },
    itemImgSrc(item) {
      return item.images.length ? item.images[0].image : undefined
    }
  },
  created() {
    this.userTypeAdmin = this.$store.state.AppActiveUser.userType
  },
  watch: {
    discounts: function() {
      if (
        !this.item.hasOwnProperty('superAdminDiscountPriceFrom') &&
        this.isDiscounted()
      ) {
        // use price as default
        let price = this.item.price

        const rule = this.discounts.filter(discount=>discount.productIds.some(id=>id===this.item.productStoreId))[0].rule;

        // has bulk discount rule & clinic discount, bulk discount < clinic discount
        if (rule && this.hasClinicDiscount(this.item.productStoreId) && this.item.originalPrice) price = this.item.originalPrice

        // has discount use listPrice as price
        if (rule.discount_price && rule.discount_price === 'List Price') price = this.item.listPrice;

        this.item.superAdminDiscountPrice = price / 100
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-view-item {
  .item-name,
  .item-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .item-name {
    -webkit-line-clamp: 2;
  }

  .item-description {
    -webkit-line-clamp: 5;
  }

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
